<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 자재명 -->
          <c-text
            label="LBL0003254"
            name="materialName"
            v-model="searchParam.materialName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="MATERIAL_KIND_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="materialKindCd"
            label="자재구분"
            v-model="searchParam.materialKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-material-type
            type="search"
            label="자재분류"
            name="materialTypeCd"
            v-model="searchParam.materialTypeCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 자재목록 -->
        <c-table
          ref="materialTable"
          title="LBL0003256"
          tableId="materialTable"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="addData" />
                <c-btn label="LBLREMOVE" v-if="editable && dataeditable && updateMode" icon="remove" @btnClicked="deleteData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveMaterial"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="자재관리번호"
                  name="materialNo"
                  v-model="data.materialNo">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :required="true"
                  :editable="editable && dataeditable"
                  label="LBL0003254"
                  name="materialName"
                  v-model="data.materialName">
                </c-text>
              </div>
              <div class="col-12">
                <c-material-type
                  :editable="editable && dataeditable"
                  type="edit"
                  label="자재분류"
                  name="materialTypeCd"
                  v-model="data.materialTypeCd" />
              </div>
            </template>
          </c-card>
        </q-form>
      </div> -->
    </div>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'material-manage',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'materialKindName',
            field: 'materialKindName',
            label: '자재구분',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'upMaterialTypeName',
            field: 'upMaterialTypeName',
            label: '자재 대분류',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialTypeName',
            field: 'materialTypeName',
            label: '자재 중분류',
            style: 'width:140px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialNo',
            field: 'materialNo',
            // 자재관리번호
            label: '자재관리번호',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 자재명
            label: 'LBL0003254',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
        materialCd: '',
        materialName: '',
        chemMaterialFlag: null,
        materialTypeCd: '',
        materialKindCd: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      data: {
        materialCd: '',  // 자재코드
        materialNo: '',  // 자재관리번호
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        chemMaterialFlag: '',  // 화학자재 여부
        materialGroupCd: '',  // 자재그룹 코드
        materialGroupName: '',  // 자재그룹 명
        materialTypeCd: null,  // 자재분류코드
        useFlag: 'Y',  // 사용여부
      },
      editable: true,
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.user.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.saveUrl = transactionConfig.mdm.user.insert.url;
      this.listUrl = selectConfig.mdm.mam.material.list.url;
      this.detailUrl = selectConfig.mdm.mam.material.get.url;
      this.checkUrl = selectConfig.mdm.mam.material.check.url;
      this.insertUrl = transactionConfig.mdm.mam.material.insert.url;
      this.updateUrl = transactionConfig.mdm.mam.material.update.url;
      this.deleteUrl = transactionConfig.mdm.mam.material.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.reset();
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.materialCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addData() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        materialCd: '',  // 자재코드
        materialNo: '',  // 자재관리번호
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        chemMaterialFlag: '',  // 화학자재 여부
        materialGroupCd: '',  // 자재그룹 코드
        materialGroupName: '',  // 자재그룹 명
        materialTypeCd: null,  // 자재분류코드
        useFlag: 'Y',  // 사용여부
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        materialCd: '',  // 자재코드
        materialNo: '',  // 자재관리번호
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        chemMaterialFlag: '',  // 화학자재 여부
        materialGroupCd: '',  // 자재그룹 코드
        materialGroupName: '',  // 자재그룹 명
        materialTypeCd: null,  // 자재분류코드
        useFlag: 'Y',  // 사용여부
      };
    },
    saveMaterial() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.materialNo);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 자재코드가 존재합니다.', // 동일한 자재코드가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.materialCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  },
};
</script>
